import React from "react"
import * as Icon from "react-feather"

const navigationConfig = [
  {
    id: '1',
    title: "Dashboard",
    type: "item",
    icon: <Icon.Home size={20} />,
    badge: "warning",
    navLink: "/"
  },
  {
    id: '2',
    title: "Request",
    type: "item",
    icon: <Icon.Inbox size={20} />,
    navLink: "/requests",
  },
  {
    id: '3',
    title: "Calendar",
    type: "item",
    icon: <Icon.Calendar size={20} />,
    navLink: "/sessions"
  },
  {
    id: '4',
    title: "User",
    type: "item",
    icon: <Icon.User size={20} />,
    navLink: "/users"
  },
  {
    id: '5',
    title: "Organization",
    type: "item",
    icon: <Icon.Layers size={20} />,
    navLink: "/organizations"
  },
  {
    id: '6',
    title: "Profiles",
    type: "item",
    icon: <Icon.Book size={20} />,
    navLink: "/profiles"
  },
  {
    id: '7',
    title: "Evaluations",
    type: "item",
    icon: <Icon.Flag size={20} />,
    navLink: "/evaluations"
  },
  {
    id: '8',
    title: "Classes",
    type: "item",
    icon: <Icon.Copy size={20} />,
    navLink: "/classes"
  },
  {
    id: '9',
    title: "Reports",
    type: "item",
    icon: <Icon.Flag size={20} />,
    navLink: "/reports"
  },
  {
    id: 'A',
    title: "Years",
    type: "item",
    icon: <Icon.Flag size={20} />,
    navLink: "/years"
  },
  {
    id: 'B',
    title: "Settings",
    type: "item",
    icon: <Icon.Settings size={20} />,
    navLink: "/settings"
  },
]

export default navigationConfig
